







































































import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({
	components: {
		BaseButton: () => import('../components/base/BaseButton.vue')
	}
})
export default class FileInput extends Vue {
	@Prop({ default: true })
	configEmpty: boolean;

	file = null as unknown as Blob;
	fileName = '';

	handleFileChange(event: any): void {
		const selectedFile = event.target.files[0];
		console.log(event.target.files[0]);
		this.file = selectedFile;
		this.fileName = event.target.files[0].name;
	}

	importData(): void {
		if (this.file) {
			const reader = new FileReader();
			reader.readAsText(this.file, 'UTF-8');
			reader.onload = function (evt) {
				if (evt.target) {
					try {
						const obj = evt.target.result;
						const preResult = JSON.parse(String(obj));
						const workingPeriods = JSON.stringify(preResult.workingPeriods);
						const globalId = JSON.stringify(preResult.globalID);
						const clientId = JSON.stringify(preResult.clientId);
						localStorage.setItem('MeetingTimer-MeetingsConfig', workingPeriods);
						localStorage.setItem('MeetingTimer-GlobalId', globalId);
						localStorage.setItem('MeetingTimer-ClientId', clientId);
					} catch (e) {
						console.log(e);
					}
				}
			};
			this.$emit('import');
		}
	}

	mergeData(): void {
		if (this.file) {
			const reader = new FileReader();
			reader.readAsText(this.file, 'UTF-8');
			reader.onload = (evt) => {
				if (evt && evt.target && evt.target.result) {
					this.$emit('merge-new-array', JSON.parse(String(evt.target.result)));
				}
			};
		}
	}
}
