var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex items-center justify-center w-full"},[_c('label',{staticClass:"flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600",attrs:{"for":"dropzone-file"}},[_c('div',{staticClass:"flex flex-col items-center justify-center pt-5 pb-6"},[_c('span',{staticClass:"w-12 h-12 mb-3 text-5xl text-gray-500 mdi-import mdi"}),_vm._m(0),(_vm.fileName.length > 0)?_c('p',{staticClass:"text-xs text-gray-500 dark:text-gray-400"},[_vm._v(_vm._s(_vm.fileName))]):_vm._e()]),_c('input',{staticClass:"hidden",attrs:{"id":"dropzone-file","name":"dropzone-file","type":"file"},on:{"change":_vm.handleFileChange}})])]),_c('div',{staticClass:"flex justify-center p-2 mt-3 space-x-2"},[_c('BaseButton',{attrs:{"button":{
				text: 'Cancel',
				color: 'gray-50',
				hoverColor: 'gray-600',
				textColor: 'gray-600',
				textHoverColor: 'white'
			}},on:{"button-clicked":function($event){return _vm.$emit('close-dialog')}}}),_c('BaseButton',{attrs:{"button":{
				text: _vm.configEmpty ? 'Import' : 'Merge',
				color: _vm.file ? 'primaryColor' : 'gray-50',
				hoverColor: _vm.file ? 'blue-500' : 'gray-600',
				textColor: _vm.file ? 'white' : 'gray-600',
				textHoverColor: 'white'
			},"disabled":_vm.file === null},on:{"button-clicked":function($event){_vm.configEmpty ? _vm.importData() : _vm.mergeData()}}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"mb-2 text-sm text-gray-500 dark:text-gray-400"},[_c('span',{staticClass:"font-semibold"},[_vm._v("Click to upload")]),_vm._v(" (type: application/json)")])}]

export { render, staticRenderFns }